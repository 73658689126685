import logo from './images/default-img.png';
import './App.css';
import {React, useRef} from 'react';
import { useState, useEffect } from 'react';
import {Link, BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import AdminLogin from './AdminLogin.js';
import AdminPage from './AdminPage.js';
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs, collection, query, where, orderBy } from "firebase/firestore";
import { Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const App = () => {
  return (
    <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/:id" element={<WatchPage />}/>
            <Route path="/admin" element={<AdminLogin />}/>
            <Route path="/myfilms" element={<AdminPage />}/>
          </Routes>
        </BrowserRouter>
    </>
  )
};

const HomePage = () => {

  const [films, setFilms] = useState();
  const [semesters, setSemesters] = useState();
  const [search, setSearch] = useState();
  const [showRestrictedFilms, setShowRestricted] = useState(false);
  const [showUnavailableFilms, setShowUnavailable] = useState(false);
  const [showIndependent, setShowIndependent] = useState(false);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
      authDomain: "buzz-studios-7f814.firebaseapp.com",
      databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
      projectId: "buzz-studios-7f814",
      storageBucket: "buzz-studios-7f814.appspot.com",
      messagingSenderId: "103909984010",
      appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
      measurementId: "G-GY05FJ1M7L"
    };
    
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const fetch = async () => {
      var db = getFirestore(app);
      var docRef = collection(db, "films");
      var q = query(docRef, orderBy("order"));
      var docSnap = await getDocs(q);

      var films = [];
      docSnap.forEach((doc) => {
        var data = doc.data();
        data['id'] = doc.id;
        films.push(data);
      });
      const semesters = Array.from(new Set(films.map(film => film.semester)));
      
      setFilms(films);
      setSemesters(sortSemesters(semesters));
      setSearch("");
    }
    fetch();
  }, []);


  const getFilmsInSemesterRow = (semesterFilms) => {
    const rows = [];

    for (let i = 0; i < semesterFilms.length; i += 25) {
      const row = semesterFilms.slice(i, i + 25);
      rows.push(row);
    }

    return rows;
  };

  function sortSemesters(semesters) {
    const seasonOrder = {
      Spring: 0,
      Summer: 1,
      Fall: 2,
      'Date Unknown': 3,
      'Extra Material': 4
    };
  
    semesters.sort((a, b) => {
      const [seasonA, yearA] = a.split(' ');
      const [seasonB, yearB] = b.split(' ');
  
      // Check if either film has "Date Unknown"
      const hasDateUnknownA = seasonA === 'Date' && yearA === 'Unknown';
      const hasDateUnknownB = seasonB === 'Date' && yearB === 'Unknown';

      const hasExtraMaterialA = seasonA === 'Extra' && yearA === 'Material';
      const hasExtraMaterialB = seasonB === 'Extra' && yearB === 'Material';
  
      // Handle "Date Unknown" films
      if (hasDateUnknownA && hasDateUnknownB) {
        return 0; // Keep the order as is
      } else if (hasDateUnknownA || hasExtraMaterialA) {
        return 1; // Move "Date Unknown" film to the end
      } else if (hasDateUnknownB || hasExtraMaterialB) {
        return -1; // Move "Date Unknown" film to the end
      }
  
      // Compare years first
      if (yearA !== yearB) {
        return parseInt(yearB) - parseInt(yearA);
      }
  
      // If years are the same, compare seasons
      return seasonOrder[seasonB] - seasonOrder[seasonA];
    });
  
    return semesters;
  }

  function filterBySearch(films, data) {
    var newFilms = [[]];

    // if (search == "") {
    //   return films;
    // } else {
    //   for (let e = 0; e < films[0].length; e++) {
    //     if (films[0][e].title.toLowerCase().trim().includes(search.toLowerCase().trim())) {
    //       newFilms[0].push(films[0][e]);
    //     }
    //   }
    // }

    var hidden = 0;
    var noSearch = 0;

    for (let e = 0; e < films[0].length; e++) {
      if (films[0][e].independent === undefined) { films[0][e].independent = false; }
      if ((search !== "" && !films[0][e].title.toLowerCase().trim().includes(search.toLowerCase().trim())) || (films[0][e].independent != showIndependent)) {
        noSearch++;
        continue;
      }
      if (!showRestrictedFilms && films[0][e].access === "restricted") {
        hidden++;
        continue;
      }
      if (!showUnavailableFilms && films[0][e].access === "unavailable") {
        hidden++;
        continue;
      }
      newFilms[0].push(films[0][e]);
    }

    if (data == 0) {
      return newFilms;
    } else if (data == 1) {
      return hidden;
    } else if (data == 2) {
      return noSearch != films[0].length;
    }
  }

  return (
    <div className="homepage">
      <BuzzHeader/>

      <div className="intro-box">
        {/* <p className="intro-header"><strong>We are currently migrating the archive to a new content delivery system. Some films may be unavailable until this is completed.</strong></p>
        <br></br> */}
        <p className="intro-header"><strong>Welcome to the Buzz Studios Film Archive.</strong></p>
        <p className="intro-body">
          Buzz Studios was chartered in 2001 by a group of students 
          hoping to create a community of filmmakers, storytellers, 
          and artists that would enrich the Georgia Tech campus with 
          its work and its welcoming environment. 22 years in, this 
          mission statement guides us all as we continue to tell the 
          stories of our members. In order to honor our past and look 
          forward to our future, we have created the Buzz Studios Film 
          Archive, intended to document our club's entire body of work 
          as thoroughly as humanly possible. It is our hope that this 
          website will be maintained well into the future and continue to 
          document the work of Georgia Tech's longest-running filmmaking 
          organization.<br></br><br></br>- Ethan Ollins, President 2023-2025<br></br><br></br>
          This archival mission is an ever-ongoing work in progress. Alumni 
          are strongly encouraged to reach out to help us realize this vision. 
          If you would like to help contribute to this archive, please reach 
          out at:<br></br><br></br><strong><a style={{color: "white"}} href="mailto:info@buzzstudios.org">info@buzzstudios.org</a></strong>
          </p>
        </div>
      <main>
        <div className="content">
          <div class="search-bar" style={{marginBottom: 0}}>
              <input onChange={(e) => setSearch(e.target.value)} type="text" class="input-field" placeholder="Search..."/>
          </div>
          <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline"}}>
            <input onClick={() => setShowRestricted(!showRestrictedFilms)} checked={showRestrictedFilms} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowRestricted(!showRestrictedFilms)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Access-Restricted Films</p>
          </div>
          <div style={{color: "white", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: -30, alignItems: "baseline"}}>
            <input onClick={() => setShowUnavailable(!showUnavailableFilms)} checked={showUnavailableFilms} type="checkbox" style={{transform: "scale(1.5)"}}/><p onClick={() => setShowUnavailable(!showUnavailableFilms)} style={{marginLeft: 16, fontSize: 18, paddingBottom: 3, cursor: "pointer", userSelect: "none"}}>Show Unreleased Films</p>
          </div>

          <Button variant="contained" style={{backgroundColor: "black"}} onClick={() => {setShowIndependent(!showIndependent)}}>{showIndependent ? "See Buzz Studios Films" : "See Independent Films"}</Button>
          {showIndependent && <p class="intro-body"> This section contains films that were not officially sanctioned<br></br>by Buzz Studios, but whose crews consisted of majority active<br></br>or former Buzz Studios members.</p>}

          {semesters !== undefined && semesters.map((semester, i) => {
            const semesterFilms = films.filter(film => film.semester === semester);
            const filmsInRow = filterBySearch(getFilmsInSemesterRow(semesterFilms), 0);
            const filmsNotShown = filterBySearch(getFilmsInSemesterRow(semesterFilms), 1);
            const showHeading = filterBySearch(getFilmsInSemesterRow(semesterFilms), 2);

            //if (filmsInRow[0].length > 0) {
              return (
                showHeading && <div className="semester" key={semester}>
                  <h2>{semester}</h2>
                  {filmsNotShown == 1 && <h4 style={{marginTop: -20, color: "gray"}}><i>1 hidden film not displayed below.</i></h4>}
                  {filmsNotShown > 1 && <h4 style={{marginTop: -20, color: "gray"}}><i>{filmsNotShown + " hidden films not displayed below."}</i></h4>}
                  <div className="films">
                    {filmsInRow.map((filmRow, index) => (
                      <div className="film-row" key={index}>
                        {filmRow.map(film => (
                          <Film film={film}/>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              );
            //}
          })}
        </div>
      </main>
    </div>
  );
};

const WatchPage = () => {
  const { id } = useParams();
  const [filmData, setFilmData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [url, setURL] = useState(false);
  const [urlLoaded, setLoaded] = useState(false);
  const [otherFilms, setOtherFilms] = useState({});
  const [numDirectors, setNumDirectors] = useState(1);
  const [scriptURL, setScriptURL] = useState("");
  const [captionsURL, setCaptionsURL] = useState("");
  const [cast, setCast] = useState();
  const [usedPassword, setUsedPassword] = useState();
  const [showCast, setShowCast] = useState();
  const [otherCast, setOtherCast] = useState({});
  const [bios, setBios] = useState([]);
  const [allFilms, setAllFilms] = useState();

  const videoRef = useRef();

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
      authDomain: "buzz-studios-7f814.firebaseapp.com",
      databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
      projectId: "buzz-studios-7f814",
      storageBucket: "buzz-studios-7f814.appspot.com",
      messagingSenderId: "103909984010",
      appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
      measurementId: "G-GY05FJ1M7L"
    };
    
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();

    const fetchFilms = async () => {
      setFilmData({});
      setAuthenticated(false);
      setURL(false);
      setLoaded(false);
      setOtherFilms({});
      setOtherCast({});
      setNumDirectors(1);
      setScriptURL("");
      setCaptionsURL("");
      setCast();
      setUsedPassword("");
      setShowCast(false);

      var db = getFirestore(app);
      var docRef = doc(db, "films", id);
      var docSnap = await getDoc(docRef);
      var data = docSnap.data();
      data['id'] = doc.id;

      docRef = collection(db, "films");
      var q = query(docRef, orderBy("order"));
      docSnap = await getDocs(q);
      setAllFilms(docSnap);

      var filmsByDirector = [];

      var directors = data['director'];
      var names = splitNames(directors);
      setNumDirectors(names.length);

      var bios = [];

      docSnap.forEach((doc) => {
        var film = doc.data();

        for (var i = 0; i < names.length; i++) {
          if (film.director.includes(names[i]) && film.title != data.title) {
            film['id'] = doc.id;
            filmsByDirector.push(film);
          }
        }
      }
    );

      setFilmData(data);
      setOtherFilms(filmsByDirector);

      docRef = collection(db, "directors");
      docSnap = await getDocs(docRef);
      docSnap.forEach((doc) => {
        var director = doc.data();

        if (names.includes(director.name)) {
          bios.push(director);
        }
      });

      setBios(bios);

      if (data.access === "released") {
        signInAnonymously(auth)
          .then(() => {
            fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "title": data.title,
              "film": data.filmfile,
              "uid": auth.currentUser.uid
            })
          })
          .then(response => response.json())
          .then(d => {
            setURL(d.url);
            setAuthenticated(true);
          })
        })
        .catch((error) => {
          alert("Could not authenticate.")
        });
      }
    }
    fetchFilms();
  }, [id]);

  var password = "";

  const authenticate = () => {
    const auth = getAuth();

    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.filmfile,
        "code": password
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.url != "403") {
        signInAnonymously(auth)
        .then(() => {
            setURL(data.url);
            setAuthenticated(true);
            setUsedPassword(password);
        })
        .catch((error) => {
          alert("Could not authenticate.");
        });
      } else {
        alert("Incorrect access code.");
      }
    })
  }

  useEffect(() => {
    if (authenticated && !urlLoaded) {
        setLoaded(true);
        videoRef.current?.load();
        
        if (filmData.script !== undefined && filmData.script !== "") {
          requestScript();
        }
        if (filmData.captions !== undefined && filmData.captions !== "") {
          requestCaptions();
        }
        if (filmData.cast !== undefined && filmData.cast !== "" && filmData.cast.includes(".json")) {
          requestCast();
        }
    }
  }, [authenticated]);

  useEffect(() => {
    if (allFilms !== undefined && cast !== undefined)
    {
      var filmsWithCast = [];

      allFilms.forEach((doc) => {
        var film = doc.data();

        if (doc.id !== id) {
          for (var i = 0; i < cast.length; i++) {
            var name = cast[i][0];
            
            if (film.cast !== undefined) {

              for (var j = 0; j < film.cast.length; j++) {
                if (film.cast[j][0] == name) {
                  if (!(name in filmsWithCast)) {
                    filmsWithCast[name] = [];
                  }
                  var f = doc.data();
                  f.id = doc.id;

                  if (!(filmsWithCast[name].some(e => e.id === f.id))) {
                    filmsWithCast[name].push(f);
                  }
                }
              }
            }
          }
        }
      });
    }
        
    setOtherCast(filmsWithCast);
  }, [cast]);

  const requestScript = () => {
    const auth = getAuth();
    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.script,
        "uid": auth.currentUser.uid,
        "code": usedPassword
      })
    })
    .then(response => response.json())
    .then(d => {
      setScriptURL(d.url);
    });
  }

  const requestCaptions = () => {
    const auth = getAuth();
    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.captions,
        "uid": auth.currentUser.uid,
        "code": usedPassword
      })
    })
    .then(response => response.json())
    .then(d => {
      setCaptionsURL(d.url);
    });
  }

  const requestCast = () => {
    const auth = getAuth();
    fetch('https://us-east1-buzz-studios-7f814.cloudfunctions.net/request-film', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "title": filmData.title,
        "film": filmData.cast,
        "uid": auth.currentUser.uid,
        "code": usedPassword
      })
    })
    .then(response => response.json())
    .then(d => {
      fetch(d.url)
      .then((response) => response.json())
      .then((cast) => {
        if (cast !== undefined) {
          setCast(cast.cast);
        }
      })
      .catch((error) => {
        console.error(error);
      })
    });
  }

  const styles = {
    tableContainer: {
      maxWidth: '600px',
      margin: '20px auto',
      borderCollapse: 'collapse',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    table: {
      width: '100%',
      border: '1px solid #ddd',
    },
    th: {
      backgroundColor: '#f4f4f4',
      padding: '12px',
      textAlign: 'left',
      fontWeight: 'bold',
      borderBottom: '2px solid #ddd',
    },
    td: {
      padding: '10px',
      borderBottom: '1px solid #ddd',
    },
    trHover: {
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    trHoverActive: {
      backgroundColor: '#f9f9f9',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '24px',
    },
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark", // Enables dark mode
      background: {
        default: "#000", // Black background
        paper: "#111", // Slightly lighter for table rows/paper
      },
      text: {
        primary: "#fff", // White text
        secondary: "#ccc", // Light gray text for secondary
      },
    },
  });

  return (
    <div>
      <BuzzHeader/>
      <br></br><br></br>
      <div className="watch-page">
        <div className={filmData.access == "released" || authenticated ? ("video-container") : ("msg-container")}>
          {filmData.access == "released" || authenticated? (
            <video ref={videoRef} crossOrigin='anonymous' playsInline controls controlsList="nodownload" class="player">
              <source src={url}/>
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={captionsURL}
                 
              />
            </video>
          ) : filmData.access == "unavailable" ? (
            <div className="coming-soon">
                <p class="cs"><b>Coming Soon</b></p>
                <p class="css">Please check back later.</p>
            </div>
          ) : filmData.access == "restricted" ? (
            <div className="coming-soon">
                <p class="cs"><b>Restricted</b></p>
                <p class="css">This film is restricted as it has either not been finalized or has not yet been publicly released. Please enter the access code.</p>
                <br></br><input type="password" class="accesscode" onChange={(event) => password = event.target.value}></input>
                <button onClick={authenticate} class="submitaccesscode">Submit</button>
            </div>
          ) : <div className="coming-soon">
              <p class="cs"><b>Loading...</b></p>
            </div>}
        </div>
        {filmData.access !== undefined && <div className={!filmData.independent || filmData.independent === undefined ? "info-box" : "info-box-indep"}>
          
          {!showCast && <><strong><p class="title">{filmData.title}</p></strong>
          <p class="subheading">Directed by {filmData.director}</p>
          <p class="subheading">Starring {filmData.stars}</p><br></br>
          <p class="synopsis">{filmData.synopsis}</p>

          {scriptURL !== "" && <Link style={{textDecoration: 'none'}} id="scriptLink" target="_blank" download to={scriptURL}><Button style={{margin: "0 auto", width: 200, display: "block", marginTop: "25px"}} variant="contained" id="scriptDownload">Download Script</Button></Link>}
          </>}

          {showCast && <><ThemeProvider theme={darkTheme}>
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table" mode="dark">
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell align="right"><strong>Role</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cast.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell size="small" component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell size="small" align="right">{row[1]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </ThemeProvider></>}

        {cast !== undefined && <Button onClick={() => {setShowCast(!showCast)}} style={{margin: "0 auto", width: 200, display: "block", marginTop: scriptURL !== "" ? "10px" : "25px"}} variant="contained" id="scriptDownload">{showCast ? "Hide Cast" : "Show Cast"}</Button>}
        </div>}
      </div>
      <br></br>

      {(bios.length > 0) && <div style={{marginTop: "0px"}} className="watch-page">
          <main>
            {bios.map((bio => {
              return (
                <>
                <h2 style={{marginBottom: -10}}>About {bio.name}</h2>
                <div className="bio" >
                  <img src={"https://firebasestorage.googleapis.com/v0/b/buzz-studios-7f814.appspot.com/o/" + bio.headshot + "?alt=media"}></img>
                  <p>{bio.bio}{bio.bio}{bio.bio}</p>
                </div>
                </>
              )
            }))}
          </main>
        </div>}

      {(otherFilms.length > 0 && filmData.director != "N/A") && <div style={{marginTop: "0px"}} className="watch-page">
          <main>
          <div className="semester"  key={-1}>
            <h2>{numDirectors == 1 ? "Other Films By This Director" : "Other Films By These Directors"}</h2>
            <div className="films" >
              <div className="film-row" style={{display: "flex", alignItems: "center", flexDirection: "column", flexFlow: "wrap", justifyContent: "center"}} key={0}>
                {otherFilms.map(film => (
                  <Film film={film}/>
                ))}
              </div>
            </div>
          </div>
          </main>
        </div>}
        
       {/* {(showCast && otherCast != {}) && <div style={{marginTop: "0px"}} className="watch-page">
        <main>
        {Object.keys(otherCast).map((actor, i) => {
          return (
            <>
            <div className="semester"  key={i}>
              <h2>Other Films Featuring {actor}</h2>
              
              <div className="films" >
                <div className="film-row" style={{display: "flex", alignItems: "center", flexDirection: "column", flexFlow: "wrap", justifyContent: "center"}} key={0}>
                  {otherCast[actor].map(film => (
                    <Film film={film}/>
                  ))}
                </div>
              </div>
            </div>
            </>
          )
        })}
        </main>
      </div>} */}
    </div>
  );
};

const BuzzHeader = () => {
  return (
    <div className="header">
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Link to="/">
            <img src={logo} alt="Buzz Studios Filmmaking Club" />
          </Link>
          </div>
      </div>
  )
};

const MenuButton = (props) => {
  return (
    <div style={{backgroundColor: "#222222", width: props.Width, height: "50px", justifyContent: "center", alignItems: "center", display: "flex", borderRadius: "5px"}}>
      <strong style={{color: "white", fontSize: "20px", marginBottom: "5px"}}>{props.Text}</strong>
    </div>
  )
}

const Film = (props) => {
  return (
    <div className="film" style={{margin: props.condensed ? "-20px" : "0px"}} key={props.film.id}>
      <Link to={`/${props.film.id}`}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/buzz-studios-7f814.appspot.com/o/` + props.film.thumbnail + `?alt=media`} />
        <div className="overlay">
          {
            (props.film.title.includes(":") && <h3>{props.film.title.split(":")[0] + ":"}<br></br>{props.film.title.split(":")[1]}</h3>) || !props.film.title.includes(":") && <h3>{props.film.title}</h3>
          }
          {
            props.film.independent && <p className="independent-icon"><strong>INDEPENDENT</strong></p>
          }
          {
            props.film.captions && props.film.captions !== "" && <p className="cc-icon" style={{marginTop: props.film.independent ? -10 : -18 }}><strong>CC</strong></p>
          }
        </div>
      </Link>
    </div>
  )
}

function splitNames(nameList) {
  let names = nameList.split(',');

  let finalNames = [];

  names.forEach(name => {
      if (name.includes(' and ')) {
          finalNames.push(...name.split(' and ').map(n => n.trim()));
      } else {
          finalNames.push(name.trim());
      }
  });

  return finalNames;
}

export default App;